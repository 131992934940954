import axios from "axios";

const getDefaultState = () => {
  return {
    newTicket: {
      conversationId: "",
      customFields: [
        { id: "49151628", value: "" },
        { id: "49151648", value: "" },
        { id: "360020476552", value: "" },
        { id: "360020476112", value: "" },
      ],
      macro: "",
      transcript: [],
      user: {
        name: "",
        phone: ""
      }
    },
    loading: false,
    isDisabled: false,
  };
};

const state = getDefaultState();

const getters = {
  loading: state => state.loading,
  newTicket: state => state.newTicket,
  isDisabled: state => state.isDisabled
};

const actions = {
  resetTicket({ commit }) {
    commit("resetTicket");
  },
  async sendTicket({ commit }, ticket) {
    commit("setLoadingStatus", true);
    commit("setOperatorEmail");
    axios
      .post(
        "https://moura-conversation-prod.herokuapp.com/v1/liveperson/conversation-closed/d2fcdbdc-94e2-4563-96aa-4c2ed62083b3",
        ticket
      )
      .then(() => {
        alert("Pronto. Dados enviados!");
        commit("resetTicket");
        commit("setLoadingStatus", false);
        commit("setOperatorStatus", true);
      })
      .catch(error => {
        commit("setLoadingStatus", false);
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },
  setTicketState({ commit }, data) {
    commit("setTicketState", data);
  },
  setTicketTranscript({ commit }, data) {
    commit("setTicketTranscript", data);
  },
  setTicketUserCity({ commit }, data) {
    commit("setTicketUserCity", data);
  },
  setTicketUserName({ commit }, data) {
    commit("setTicketUserName", data);
  },
  setTicketUserPhone({ commit }, data) {
    commit("setTicketUserPhone", data);
  }
};

const mutations = {
  resetTicket: state => {
    state.newTicket.customFields[0].value = "";
    state.newTicket.customFields[1].value = "";
    state.newTicket.macro = "";
  },
  setLoadingStatus: (state, status) => {
    state.loading = status;
  },
  setTicketState: (state, data) => {
    state.newTicket.customFields[1].value = data;
  },
  setTicketTranscript: (state, data) => {
    state.newTicket.conversationId = data[0].dialogId;
    state.newTicket.transcript = data;
  },
  setTicketUserCity: (state, data) => {
    state.newTicket.customFields[0].value = data;
  },
  setTicketUserName: (state, data) => {
    state.newTicket.user.name = data.visitorName;
  },
  setTicketUserPhone: (state, data) => {
    state.newTicket.user.phone = data.customerDetails.imei;
  },
  setOperatorEmail: state => {
    if (state.newTicket.customFields[2].value == "Isaac Silva") {
      state.newTicket.customFields[3].value = "isaac.silva@ops.tds.company"
    } if (state.newTicket.customFields[2].value == "Kleber Souza") {
      state.newTicket.customFields[3].value = "kleber.souza@ops.tds.company"
    } if (state.newTicket.customFields[2].value == "Dayanne Moraes") {
      state.newTicket.customFields[3].value = "dayanne.moraes@ops.tds.company"
    } if (state.newTicket.customFields[2].value == "Alexsander Silva") {
      state.newTicket.customFields[3].value = "alexsander.silva@ops.tds.company"
    }
  },
  setOperatorStatus: (state, status) => {
    state.isDisabled = status;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
