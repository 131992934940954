import axios from "axios";

const headers = { Authorization: "85c5c62e84e10517c3b1824adee58a1f" };
const params = { account: "MOURA_FACIL" };
const url = "https://moura-emissary-prod.herokuapp.com/v1/zendesk/macros";

const state = {
  macros: []
};

const getters = {
  allMacros: state => state.macros
};

const actions = {
  async fetchMacros({ commit }) {
    const response = await axios.get(url, { params, headers });
    commit("setMacros", response.data);
  }
};

const mutations = {
  setMacros: (state, macros) => (state.macros = macros)
};

export default {
  state,
  getters,
  actions,
  mutations
};
