import Vue from "vue";
import Vuex from "vuex";

import macros from "./modules/macros";
import states from "./modules/states";
import tickets from "./modules/tickets";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    macros,
    states,
    tickets
  }
});
