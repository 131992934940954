import axios from "axios";

const headers = { Authorization: "85c5c62e84e10517c3b1824adee58a1f" };
const params = { filter: "moura0800.estado" };
const url = "https://moura-emissary-prod.herokuapp.com/v1/zendesk/tags";

const state = {
  states: []
};

const getters = {
  allStates: state => state.states
};

const actions = {
  async fetchStates({ commit }) {
    const response = await axios.get(url, { params, headers });

    for (var i = response.data.length - 1; i >= 0; i--) {
      response.data[i].slug = response.data[i].slug.toUpperCase();
    }

    commit("setStates", response.data);
  }
};

const mutations = {
  setStates: (state, states) => (state.states = states)
};

export default {
  state,
  getters,
  actions,
  mutations
};
